import React from 'react';
import ReactDOM from 'react-dom';
import HSCDocx from './HSCDocx/HSCDocx';


// uncomment this block to "defer" ReactDOM.render and expose it globaly
window.HSCDocx = {
    mount: (props, container) => {
        ReactDOM.render(<HSCDocx {...props} />, container);
    },
    unmount: (container) => {
        ReactDOM.unmountComponentAtNode(container);
    }
}


console.log("HSCDocx version: 0.0.0.1.C")