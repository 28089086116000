import React, { Component } from "react";
import { saveAs } from 'file-saver';
import HTMLtoDOCX from 'html-to-docx';

export default class HSCDocx extends Component {
    constructor(props) {
        super(props);
        this.state = { val: '' }
    }
        downloadDocx = async(params) => {
            let showFooter=this.state.footer!=null && this.state.footer!="";
            let showHeader=this.state.header!=null && this.state.header!="";
            let font=this.props.document["-fuente"]!=null && this.props.document["-fuente"]!="" ? this.props.document["-fuente"]:"Montserrat";
            let fontSize=this.props.document["-tamañoLetra"]!=null && this.props.document["-tamañoLetra"]!="" ? this.props.document["-tamañoLetra"]:22;
            
            const fileBuffer = await HTMLtoDOCX(this.state.body.join(""), this.state.header, {
                table: { row: { cantSplit: true } },
                footer: showFooter,
                pageNumber: this.props.document.pie!=null && this.props.document.pie["-mostrarPagina"]!=null ? this.props.document.pie["-mostrarPagina"]===true:false,
                header:showHeader,
                font:font,
            },this.state.footer);
            if(this.state.documento!=null && this.state.documento!="" &&
            this.state.grupo!=null && this.state.grupo!="" &&
            this.state.expediente!=null && this.state.expediente!=""
            ){
              this.props.saveDocument(
                `${this.props.REPOSITORY_API_ROOT}/docviewer/repository/document`,
                fileBuffer,
                this.state.grupo,
                this.state.expediente,
                this.state.documento,
                this.props.document["-actualizar"]
                );
              }
            if(this.props.document["-descargar"]){
              saveAs(fileBuffer, this.state.documento!=null && this.state.documento!="" ? this.state.documento+".docx":'Documento.docx');
            }
            this.props.validateChangeNextLevel(this.props.currentComponent);
            
      }

    componentDidMount() {
      let {document} = this.props;
        let formulas=[
            {
             name:"header",
             formula:document.encabezado!=null && document.encabezado["-calc"]!=null && document.encabezado["-calc"]!="" ? document.encabezado["-calc"]:""
            },
            {
                name:"footer",
                formula:document.pie!=null && document.pie["-calc"]!=null && document.pie["-calc"]!="" ? document.pie["-calc"]:""
            },
            {
                name:"documento",
                formula:document["-nombreDocumento"]!=null && document["-nombreDocumento"]!="" ? document["-nombreDocumento"]:""
            },
            {
                name:"grupo",
                formula:document["-grupo"]!=null && document["-grupo"]!="" ? document["-grupo"]:""
            },
            {
                name:"expediente",
                formula:document["-expediente"]!=null && document["-expediente"]!="" ? document["-expediente"]:""
            }
        ]
        if(document.cuerpo!=null && document.cuerpo.texto!=null){
            if (Array.isArray(document.cuerpo.texto)){
                document.cuerpo.texto.forEach(calc => {
                    if (calc["-calc"] != null && calc["-calc"] != "") {
                        formulas.push(
                            {
                                name:"text1",
                                formula:calc["-calc"]
                            }
                        );
                    }
                });
            }
            else{
                formulas.push(
                    {
                        name:"text1",
                        formula:document.cuerpo.texto["-calc"]
                    }
                );
            }
        }
        let formulaFetch = this.props.syncronouslyEvaluateFormulas(`${this.props.FORMULA_API_HOST}/formulas`,
        formulas,
            this.props.getModelObject()
            )
            formulaFetch.then((fields) => {
            let dataFetch= {
                header:fields.formulas[0].value,
                footer:fields.formulas[1].value,
                documento:fields.formulas[2].value,
                grupo:fields.formulas[3].value,
                expediente:fields.formulas[4].value,
                body:[]
            }
            if(document.cuerpo!=null && document.cuerpo.texto!=null){
                for(let i=5;i<fields.formulas.length;i++){
                    dataFetch.body.push([fields.formulas[i].value]);
                }
            }
            this.setState(
              {
                ...dataFetch
              }, () =>{
                this.downloadDocx();
              }
            );
        });
    }



    render() {
        return (null)
    }
}